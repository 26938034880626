import { useState } from "react";

import useA11y from "apps/website/hooks/useA11y";

interface IModal {
  modalId: string;
  modalTitleId: string;
  modalDescriptionId: string;
  modalButtonId: string;
  modalIsOpen: boolean;
  setModalIsOpen: (state: boolean) => void;
}

export default function useModal(initialStateIsOpen: boolean): IModal {
  const { UUID } = useA11y();
  const [ modalIsOpen, setModalIsOpen ] = useState(initialStateIsOpen);

  return {
    modalId: `modal-${UUID}`,
    modalTitleId: `modal-title-${UUID}`,
    modalDescriptionId: `modal-description-${UUID}`,
    modalButtonId: `modal-button-${UUID}`,
    modalIsOpen,
    setModalIsOpen,
  };
}
